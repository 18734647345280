<script setup>
const props = defineProps({
  immediate: true,
})

const el = ref(null)
const { width: innerWidth } = useScreen()
const { width } = useElementSize(el)
</script>

<template>
  <div
    ref="el"
    :style="{
      '--width': width,
      '--inner-width': innerWidth,
    }"
    :class="{ sweep: immediate || !usePageTransitionRunning }"
  >
    <slot />
  </div>
</template>

<style lang="postcss">
@keyframes sweep {
  to {
    transform: translateX(min(0px, calc(cols(var(--grid-cols)) + 100% * -1)));
  }
}

.sweep {
  --width: 1000;
  width: fit-content;
  transform-origin: left;
  animation: sweep calc(var(--width) * 4ms) infinite alternate ease-in-out;
}
</style>
