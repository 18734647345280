<script setup>
import { kerning } from '~/utils/kerning'

const props = defineProps({
  id: String,
})

const ele = ref(null)
const container = ref(null)
const duration = 400

const getSpans = (el, letters) =>
  letters.map((letter, idx) => {
    const span = document.createElement('span')
    span.textContent = letter
    span.style.display = 'inline-block'

    if (idx > 0) {
      const marginLeft =
        kerning[letters[idx - 1].toUpperCase() + letter.toUpperCase()]
      span.style.marginLeft = (letter === ' ' ? -0.008 : marginLeft) + 'em'
    }

    el.append(span)
    return span
  })

let spans = []

const targetEnter = ref(null)
const targetLeave = ref(null)

function onBeforeEnter(el) {
  targetEnter.value = el.querySelector('.animate-target')
  const letters = targetEnter.value.textContent.split('')

  container.value = targetEnter.value.parentElement

  targetEnter.value.textContent = ''

  spans = getSpans(targetEnter.value, letters)

  spans.forEach(span => {
    span.style.opacity = 0
    span.style.animationDelay = Math.random() * 250 + 'ms'
  })
}

function onEnter(el, done) {
  // if (container.value) {
  //   container.value.style.width = getComputedStyle(target.value).width
  // }

  // ele.value.style.height = getComputedStyle(target.value).height

  setTimeout(done, duration + 600)
}

function onAfterEnter(el) {
  spans.forEach(span => {
    span.style.opacity = 1
  })

  // if (container.value) container.value.style.width = ''
}

function onBeforeLeave(el, done) {
  targetLeave.value = el.querySelector('.animate-target')
  container.value = targetLeave.value.parentElement
}

function onLeave(el, done) {
  // container.value.style.width = getComputedStyle(target.value).width
  // ele.value.style.height = getComputedStyle(target.value).height

  const letters = targetLeave.value.textContent.split('')

  targetLeave.value.textContent = ''

  const spans = getSpans(targetLeave.value, letters)

  spans.forEach(span => {
    span.style.animationDelay = Math.random() * 250 + 'ms'
  })

  setTimeout(done, duration + 600)
}
</script>

<template>
  <div
    ref="ele"
    class="animated-headline-simple"
    :style="{ '--transition-duration': duration + 'ms' }"
  >
    <transition
      appear
      @before-enter="onBeforeEnter"
      @enter="onEnter"
      @after-enter="onAfterEnter"
      @before-leave="onBeforeLeave"
      @leave="onLeave"
    >
      <div :key="id">
        <slot />
      </div>
    </transition>
  </div>
</template>

<style lang="postcss">
.animated-headline-simple {
  --easing: cubic-bezier(0.83, 0, 0.17, 1);

  position: relative;

  /* height: 0.8em; */

  display: inline-flex;
  flex-direction: column;

  transition: width var(--transition-duration) var(--easing);

  .v-enter-active {
    position: absolute;

    @keyframes slide-enter {
      from {
        transform: translateY(-50%);
        opacity: 0;
      }
      to {
        transform: translateY(0%);
        opacity: 1;
      }
    }

    span {
      animation: slide-enter var(--transition-duration) forwards;
    }
  }

  .v-leave-active {
    + .v-leave-active {
      display: none;
    }

    transition: opacity 600ms;

    @keyframes slide-leave {
      from {
        transform: translateY(0%);
        opacity: 1;
      }
      to {
        transform: translateY(50%);
        opacity: 0;
      }
    }

    span {
      animation: slide-leave var(--transition-duration) forwards;
    }
  }
}
</style>
